import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import api from '../api'; // Import api
import { useAuth } from '../AuthContext';

const ContactForm = ({ adId, adOwnerEmail }) => {
  const { authState } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [csrfToken, setCsrfToken] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!authState.isAuthenticated) {
      setError('Morate biti prijavljeni da biste kontaktirali vlasnika oglasa.');
      return;
    }

    setSubmitted(true);
    setError('');
    setSuccess('');

    try {
      await api.post('/contact', {
        adId,
        adOwnerEmail, // Pravi email vlasnika oglasa
        name,
        email,
        message,
      }, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });
      setSuccess('Message sent successfully!');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('There was an error sending the message!', error);
      setError('There was an error sending the message. Please try again.');
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <Container fluid className="mt-4">
      <Row className="justify-content-md-center">
        <Col md={12}>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <h6 className="text-center mb-4"><strong>Kontaktirajte vlasnika oglasa ako imate pitanja</strong></h6>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Ime:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Unesite Vaše ime"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                placeholder="Unesite Vaš email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formMessage" className="mt-3">
              <Form.Label>Poruka:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Unesite poruku"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </Form.Group>
            <div className="d-grid gap-2 mt-4">
              <Button variant="primary" type="submit" disabled={submitted} size="lg">
                {submitted ? 'Sending...' : 'Send Message'}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
