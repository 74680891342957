import React, { useEffect, useState, useCallback } from 'react';
import api from '../api'; // Import api.ts
import { Card, Container, Row, Col, Button, Form, Spinner, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './HomePage.css';
import { useAuth } from '../AuthContext';
import axios from 'axios';

// Popravljanje problema sa ikonama Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const HomePage = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const { authState } = useAuth();
  const [csrfToken, setCsrfToken] = useState('');
  const [interests, setInterests] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingAds, setLoadingAds] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(6); // Postavljeno na 6
  const [mapMarkers, setMapMarkers] = useState([]); // Dodano za praćenje markera

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
        console.log('Fetched CSRF token:', response.data.csrfToken);
      } catch (error) {
        console.error('There was an error fetching the CSRF token!', error);
      }
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoadingCategories(true);
      const token = authState.token || localStorage.getItem('token');
      try {
        const response = await api.get('/categories', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const parentCategories = response.data.filter(category => category.parent === null && category.name !== 'no_category');
        const subCategories = response.data.filter(category => category.parent !== null);
        setCategories(parentCategories);
        setSubcategories(subCategories);
        setFilteredCategories(parentCategories);
        setFilteredSubcategories(subCategories);
        console.log('Fetched categories:', response.data);
      } catch (error) {
        console.error('There was an error fetching the categories!', error);
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [authState.token]);

  useEffect(() => {
    const fetchAds = async () => {
      setLoadingAds(true);
      try {
        const response = await api.get('/ads');
        setAds(response.data);
        setMapMarkers(response.data.map(ad => ({
          id: ad.id,
          title: ad.title,
          description: ad.description,
          price: ad.price,
          position: ad.latitude && ad.longitude ? [ad.latitude, ad.longitude] : null, // Provjera validnosti latitude i longitude
        })).filter(marker => marker.position)); // Filtriranje samo validnih pozicija
        console.log('Fetched ads:', response.data);
      } catch (error) {
        console.error('There was an error fetching the ads!', error);
      } finally {
        setLoadingAds(false);
      }
    };

    fetchAds();
  }, []);

  useEffect(() => {
    const filterResults = () => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      const categoryResults = categories.filter(category =>
        category.name.toLowerCase().includes(lowerCaseSearchTerm) ||
        category.description.toLowerCase().includes(lowerCaseSearchTerm)
      );

      const subcategoryResults = subcategories.filter(subcategory =>
        subcategory.name.toLowerCase().includes(lowerCaseSearchTerm) ||
        subcategory.description.toLowerCase().includes(lowerCaseSearchTerm)
      );

      const adResults = ads.filter(ad =>
        ad.title.toLowerCase().includes(lowerCaseSearchTerm) ||
        ad.description.toLowerCase().includes(lowerCaseSearchTerm) ||
        ad.price.toString().includes(searchTerm) ||
        (ad.category && ad.category.name.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (ad.subcategory && ad.subcategory.name.toLowerCase().includes(lowerCaseSearchTerm))
      );

      setFilteredCategories(categoryResults);
      setFilteredSubcategories(subcategoryResults);
      setFilteredAds(adResults);
      setCurrentPage(1); // Resetujemo paginaciju na prvu stranicu prilikom pretrage
    };

    filterResults();
  }, [searchTerm, categories, subcategories, ads]);

  useEffect(() => {
    const filterBySelectedCategories = () => {
      if (selectedCategories.length === 0) {
        setFilteredCategories(categories);
        setFilteredSubcategories(subcategories);
        setFilteredAds(ads);
      } else {
        const filteredCategoriesBySelection = categories.filter(category => selectedCategories.includes(category.id));
        const filteredSubcategoriesBySelection = subcategories.filter(subcategory => selectedCategories.includes(subcategory.parent));
        const filteredAdsBySelection = ads.filter(ad => selectedCategories.includes(ad.category.id));
        setFilteredCategories(filteredCategoriesBySelection);
        setFilteredSubcategories(filteredSubcategoriesBySelection);
        setFilteredAds(filteredAdsBySelection);
      }
      setCurrentPage(1); // Resetujemo paginaciju na prvu stranicu prilikom filtriranja po kategorijama
    };

    filterBySelectedCategories();
  }, [selectedCategories, categories, subcategories, ads]);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setInterests(searchTerm);
    }
  };

  const handleViewAds = (categoryId) => {
    navigate(`/ads?category=${categoryId}`);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleAdminLogin = () => {
    navigate('/admin/login');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategories(prevSelectedCategories =>
      prevSelectedCategories.includes(categoryId)
        ? prevSelectedCategories.filter(id => id !== categoryId)
        : [...prevSelectedCategories, categoryId]
    );
  };

  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      console.error('There was an error fetching the IP address!', error);
      return 'unknown';
    }
  };
  

  const trackPageVisit = useCallback(async (ipAddress) => {
    console.log('Tracking page visit...');
    try {
      const { user, role } = authState;
      const visitorId = user ? user.sub : null;
      console.log('ID korisnika posjetioca kojeg šaljem u backend je:', visitorId);
      
      const response = await api.post('/ad-visits/page-visits/track', 
      { visitorId, isAuthenticated: !!user, interests, ipAddress, role, userId: user ? user.sub : null }, {
        headers: {
          'X-CSRF-Token': csrfToken
        },
        withCredentials: true
      });
      console.log('Page visit tracked:', response.data);
    } catch (error) {
      console.error('There was an error tracking the page visit!', error);
      console.error(error.message);
    }
  }, [authState, csrfToken, interests]);

  useEffect(() => {
    const trackVisit = async () => {
      const ipAddress = await fetchIpAddress();
      if (csrfToken) {
        trackPageVisit(ipAddress);
      } else {
        console.log('CSRF token is missing.');
      }
    };

    trackVisit();
  }, [csrfToken, authState.user, interests, trackPageVisit]);

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = filteredCategories.slice(indexOfFirstCategory, indexOfLastCategory);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = (totalItems) => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalItems / categoriesPerPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageClick(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return totalPages > 1 ? <Pagination className="justify-content-center">{pageNumbers}</Pagination> : null;
  };

  return (
    <Container className="mt-4">
      <Row className="mb-4">
        <Col>
          <Form onKeyPress={handleSearchSubmit}>
            <Form.Group controlId="search">
              <Form.Control
                type="text"
                placeholder="Pretraži kategorije, podkategorije, oglase..."
                value={searchTerm}
                onChange={handleSearch}
                className="bg-light p-3 rounded"
              />
            </Form.Group>
          </Form>
          <div className="advertisement mt-3 text-center">
            <h4 className="animated-text">Mjesto za vašu reklamu</h4>
          </div>
        </Col>
      </Row>
      <Row className="mb-4 text-center">
        <Col>
          <div className="auth-buttons">
            <Button variant="primary" onClick={handleLogin} className="me-2">Prijava korisnika</Button>
            <Button variant="secondary" onClick={handleAdminLogin} className="me-2">Prijava admina</Button>
            <Button variant="success" onClick={handleRegister}>Registracija</Button>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col lg={3}>
          <h5 className="text-center font-weight-bold">Odaberi kategorije za pregled</h5>
          <Form className="filter-container">
            {categories.map(category => (
              <div
                key={category.id}
                className={`category-checkbox ${selectedCategories.includes(category.id) ? 'selected' : ''}`}
                onClick={() => handleCategorySelect(category.id)}
              >
                <Form.Check
                  type="checkbox"
                  id={`category-${category.id}`}
                  label={category.name}
                  onChange={() => handleCategorySelect(category.id)}
                  checked={selectedCategories.includes(category.id)}
                />
              </div>
            ))}
          </Form>
        </Col>


        <Col lg={9}>
          {loadingCategories || loadingAds ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              {searchTerm && (
                <>
                  <Row>
                    {filteredCategories.map(category => {
                      const imageUrl = category.imageUrl ? `${process.env.REACT_APP_BASE_URL}/slike_kategorija/${category.imageUrl}` : `https://via.placeholder.com/300x200?text=${category.name}`;
                      return (
                        <Col key={category.id} xs={12} md={6} lg={4} className="mb-4">
                          <Card className="h-100 shadow-sm">
                            <Card.Img
                              variant="top"
                              src={imageUrl}
                              alt={category.name}
                              onError={(e) => {
                                console.error(`Error loading image for category ${category.name}:`, e);
                                e.target.src = `https://via.placeholder.com/300x200?text=${category.name}`;
                              }}
                              onClick={() => handleViewAds(category.id)}
                            />
                            <Card.Body>
                              <Card.Title>{category.name}</Card.Title>
                              <Card.Text>{category.description}</Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-center">
                              <Button variant="primary" onClick={() => handleViewAds(category.id)}>Pogledaj oglase</Button>
                            </Card.Footer>
                          </Card>
                        </Col>
                      );
                    })}
                    {filteredSubcategories.map(subcategory => {
                      const imageUrl = subcategory.imageUrl ? `${process.env.REACT_APP_BASE_URL}/slike_podkategorija/${subcategory.imageUrl}` : `https://via.placeholder.com/300x200?text=${subcategory.name}`;
                      return (
                        <Col key={subcategory.id} xs={12} md={6} lg={4} className="mb-4">
                          <Card className="h-100 shadow-sm">
                            <Card.Img
                              variant="top"
                              src={imageUrl}
                              alt={subcategory.name}
                              onError={(e) => {
                                console.error(`Error loading image for subcategory ${subcategory.name}:`, e);
                                e.target.src = `https://via.placeholder.com/300x200?text=${subcategory.name}`;
                              }}
                              onClick={() => handleViewAds(subcategory.id)}
                            />
                            <Card.Body>
                              <Card.Title>{subcategory.name}</Card.Title>
                              <Card.Text>{subcategory.description}</Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-center">
                              <Button variant="primary" onClick={() => handleViewAds(subcategory.id)}>Pogledaj oglase</Button>
                            </Card.Footer>
                          </Card>
                        </Col>
                      );
                    })}
                    {filteredAds.map(ad => {
                      const imageUrl = ad.images && ad.images.length > 0 
                        ? `${process.env.REACT_APP_BASE_URL}/photos/original/${ad.images[0].url}`
                        : `https://via.placeholder.com/300x200?text=${ad.title}`;
                      return (
                        <Col key={ad.id} xs={12} md={6} lg={4} className="mb-4">
                          <Card className="h-100 shadow-sm">
                            <Card.Img
                              variant="top"
                              src={imageUrl}
                              alt={ad.title}
                              onError={(e) => {
                                console.error(`Error loading image for ad ${ad.title}:`, e);
                                e.target.src = `https://via.placeholder.com/300x200?text=No+Image`;
                              }}
                            />
                            <Card.Body>
                              <Card.Title>{ad.title}</Card.Title>
                              <Card.Text>{ad.description}</Card.Text>
                              <Card.Text><strong>Cijena:</strong> {ad.price} KM</Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-center">
                              <Button variant="primary" onClick={() => handleViewAds(ad.categoryId)}>Pogledaj oglas</Button>
                            </Card.Footer>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
              {!searchTerm && (
                <Row>
                  {currentCategories.map(category => {
                    const imageUrl = category.imageUrl ? `${process.env.REACT_APP_BASE_URL}/slike_kategorija/${category.imageUrl}` : `https://via.placeholder.com/300x200?text=${category.name}`;
                    return (
                      <Col key={category.id} xs={12} md={6} lg={4} className="mb-4">
                        <Card className="h-100 shadow-sm">
                          <Card.Img
                            variant="top"
                            src={imageUrl}
                            alt={category.name}
                            onError={(e) => {
                              console.error(`Error loading image for category ${category.name}:`, e);
                              e.target.src = `https://via.placeholder.com/300x200?text=${category.name}`;
                            }}
                            onClick={() => handleViewAds(category.id)}
                          />
                          <Card.Body>
                            <Card.Title>{category.name}</Card.Title>
                            <Card.Text>{category.description}</Card.Text>
                          </Card.Body>
                          <Card.Footer className="text-center">
                            <Button variant="primary" onClick={() => handleViewAds(category.id)}>Pogledaj oglase</Button>
                          </Card.Footer>
                        </Card>
                      </Col>
                    );
                  })}
                  <Col xs={12}>
                    {renderPagination(filteredCategories.length)}
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={12}>
          <h5 className="text-center font-weight-bold">Interaktivna karta Bosne i Hercegovine</h5>
          <MapContainer center={[43.9159, 17.6791]} zoom={8} style={{ height: "500px", width: "100%" }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {mapMarkers.map(marker => (
              <Marker key={marker.id} position={marker.position}>
                <Popup>
                  <strong>{marker.title}</strong><br />
                  {marker.description}<br />
                  <strong>Cijena:</strong> {marker.price} KM
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <footer className="mt-4">
            &copy; BeeCode 2024
          </footer>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
