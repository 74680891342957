import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form, ListGroup, Alert } from 'react-bootstrap';
import ReactStars from 'react-rating-stars-component';
import { useAuth } from '../AuthContext';
import api from '../api'; // Import api.ts

const ReviewModal = ({ adId, show, handleClose }) => {
  const { authState } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ rating: 0, comment: '' }); // Inicijalna vrijednost rating je sada 0 umjesto ''
  const [csrfToken, setCsrfToken] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchReviews = useCallback(async () => {
    try {
      const response = await api.get(`/reviews/${adId}`);
      setReviews(response.data);
    } catch (error) {
      console.error('There was an error fetching the reviews!', error);
    }
  }, [adId]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    if (adId && show) {
      fetchReviews();
      fetchCsrfToken();
    }
  }, [adId, show, fetchReviews]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleRatingChange = (newRating) => {
    setNewReview({ ...newReview, rating: newRating });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!authState.isAuthenticated) {
      setError('Morate biti prijavljeni da biste ostavili recenziju.');
      return;
    }

    if (!newReview.rating) {
      setError('Ocjena je obavezna.');
      return;
    }

    try {
      await api.post(
        '/reviews',
        {
          adId,
          ...newReview,
        },
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          withCredentials: true,
        }
      );
      setNewReview({ rating: 0, comment: '' }); // Resetiranje ocjene na 0 umjesto na ''
      fetchReviews();
      setError(''); // Reset error message
      setSuccess('Recenzija je uspješno poslata!');
    } catch (error) {
      console.error('There was an error submitting the review!', error);
      setError('Došlo je do greške prilikom slanja recenzije.');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Recenzije</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup className="mb-4">
          {reviews.map((review, index) => (
            <ListGroup.Item key={index}>
              <strong>Rating:</strong> {review.rating}/5<br />
              <strong>Comment:</strong> {review.comment}
            </ListGroup.Item>
          ))}
        </ListGroup>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Ocjena</Form.Label>
            <ReactStars
              count={5}
              onChange={handleRatingChange}
              size={24}
              activeColor="#ffd700"
              value={newReview.rating}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Komentar</Form.Label>
            <Form.Control
              as="textarea"
              name="comment"
              value={newReview.comment}
              onChange={handleInputChange}
              rows="3"
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Snimi recenziju
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewModal;
