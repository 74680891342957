import React, { useEffect, useState, useCallback } from 'react';
import { Table, Container, Button, Modal, Alert } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import './UserStats.css';
import api from '../api'; // Import API module
import axios from 'axios';


const UserStats = () => {
  const { authState } = useAuth();
  const [stats, setStats] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0); // State for average rating
  const [showModal, setShowModal] = useState(false);
  const [noReviewsMessage, setNoReviewsMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');

  const fetchCsrfToken = async () => {
    try {
      const response = await api.get('/csrf-token', { withCredentials: true });
      setCsrfToken(response.data.csrfToken);
      console.log('Fetched CSRF token:', response.data.csrfToken);
    } catch (error) {
      console.error('There was an error fetching the CSRF token!', error);
    }
  };

  const trackPageVisit = useCallback(async () => {
    const { user } = authState;

    if (user && user.sub) {
      try {
        const visitorData = {
          isAuthenticated: true,
          visitorId: user.sub,
          userId: user.sub,
          interests: '',
        };

        const ipAddress = await axios.get('https://api.ipify.org?format=json')
          .then(response => response.data.ip)
          .catch(error => {
            console.error('Error fetching IP address:', error);
            return null; // U slučaju greške vrati `null` ili neki default
          });


        console.log('Bilježenje posjete stranici:', visitorData);
        console.log('ID korisnika posjetioca kojeg šaljem u backend je:', user.sub);

        await api.post('/ad-visits/page-visits/track', { ...visitorData, ipAddress }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true
        });

        console.log('Posjeta uspješno zabilježena');
      } catch (error) {
        if (error.response) {
          console.error('Greška prilikom bilježenja posjete:', error.response.data);
          console.error('Status kod:', error.response.status);
          console.error('Zaglavlja:', error.response.headers);
        } else if (error.request) {
          console.error('Zahtjev nije dobio odgovor:', error.request);
        } else {
          console.error('Greška prilikom postavljanja zahtjeva:', error.message);
        }
        console.error('Kompletna greška:', error.config);
      }
    } else {
      console.error('Korisnik nije prijavljen, ne može se zabilježiti posjeta');
    }
  }, [authState, csrfToken]);

  const fetchStats = useCallback(async () => {
    const { user } = authState;

    if (user && user.sub) {
      try {
        console.log('Dohvatanje statistike za korisnika:', user.sub);
        const response = await api.get(`/ad-visits/statistics/premium/${user.sub}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const statsWithReviewsCount = await Promise.all(response.data.map(async (stat) => {
          const reviewsCountResponse = await api.get(`/reviews/count/${stat.adId}`);
          return { ...stat, reviewsCount: reviewsCountResponse.data.count };
        }));

        console.log('API odgovor:', statsWithReviewsCount);
        setStats(statsWithReviewsCount);
        setSuccessMessage('Statistika uspješno dohvacena!');
        setTimeout(() => setSuccessMessage(''), 3000);
      } catch (error) {
        console.error('Greška prilikom dohvatanja statistike:', error);
        setErrorMessage('Došlo je do greške prilikom dohvatanja statistike. Molimo pokušajte ponovo kasnije.');
        setTimeout(() => setErrorMessage(''), 3000);
      }
    } else {
      console.error('Korisnik nije prijavljen, ne može se dohvatanje statistike');
    }
  }, [authState]);

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (csrfToken && authState.user) {
      trackPageVisit();
    }
  }, [csrfToken, trackPageVisit, authState.user]);

  useEffect(() => {
    if (authState.user && authState.user.sub) {
      console.log('Trenutni korisnik:', authState.user);
      fetchStats();
    }
  }, [authState.user, fetchStats]);

  const fetchReviews = async (adId) => {
    try {
      console.log(`Dohvatanje recenzija za oglas ID: ${adId}`);
      const response = await api.get(`/reviews/${adId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log('Odgovor na recenzije:', response);
      setReviews(response.data);

      if (response.data.length === 0) {
        setNoReviewsMessage('Trenutno nemate recenzija za ovaj oglas');
      } else {
        setNoReviewsMessage('');
      }

      const averageRatingResponse = await api.get(`/reviews/average/${adId}`);
      setAverageRating(averageRatingResponse.data.averageRating); // Set average rating

      setShowModal(true);
    } catch (error) {
      console.error('Greška prilikom dohvatanja recenzija:', error);
      setErrorMessage('Došlo je do greške prilikom dohvatanja recenzija. Molimo pokušajte ponovo kasnije.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  return (
    <Container className="mt-4">
      <h2 className="text-center">Napredna Statistika</h2>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Table striped bordered hover responsive className="mt-4 user-stats-table">
        <thead>
          <tr>
            <th>Naslov Oglasa</th>
            <th>Ukupne Posjete</th>
            <th>Jedinstveni Posjetioci</th>
            <th>Rezervacije</th>
            <th>Broj Recenzija</th>
            <th>Akcije</th>
          </tr>
        </thead>
        <tbody>
          {stats.map((stat) => (
            <tr key={stat.adId}>
              <td data-label="Naslov Oglasa">{stat.title}</td>
              <td data-label="Ukupne Posjete">{stat.totalVisits}</td>
              <td data-label="Jedinstveni Posjetioci">{stat.uniqueVisitors}</td>
              <td data-label="Rezervacije">{stat.reservations}</td>
              <td data-label="Broj Recenzija">{stat.reviewsCount}</td>
              <td data-label="Akcije">
                <Button variant="primary" onClick={() => fetchReviews(stat.adId)}>
                  Prikaži Recenzije
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Recenzije za oglas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {noReviewsMessage ? (
            <p>{noReviewsMessage}</p>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Korisnik</th>
                  <th>Ocjena</th>
                  <th>Komentar</th>
                  <th>Datum</th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review) => (
                  <tr key={review.id}>
                    <td>{review.user.username}</td>
                    <td>{review.rating}</td>
                    <td>{review.comment}</td>
                    <td>{new Date(review.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <div className="text-end">
            <strong style={{ color: averageRating < 3 ? 'red' : 'green' }}>
              Prosjek ocjena: {averageRating}
            </strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserStats;
